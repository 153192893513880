import logo from "./logo.svg";
import "./App.css";
import "./css/main.css";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <h1>U KNO THA WAE TO MAH WEBSITE</h1>
          <h3>By Jasper</h3>
        </div>
      </header>
    </div>
  );
}

export default App;
